import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { EversoulCharacter } from '../../../modules/es/common/components/eversoul-character';

const EsGuidesDarkKnight: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="es">
      <ul className="breadcrumb">
        <li>
          <Link to="/eversoul/">Eversoul</Link>
        </li>
        <li className="divider">/</li>
        <li>Dark Knight - raid guide</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/eversoul/categories/category_dk.webp"
            alt="Dark Knight - raid guide"
          />
        </div>
        <div className="page-details">
          <h1>Dark Knight - raid guide</h1>
          <h2>
            Struggling with the Dark Knight Raid? This guide will help you!
          </h2>
          <p>
            Last updated: <strong>18/08/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Dark Knight Mechanics" />
        <p>
          Dark Knight is a different kind of raid compared to the other three in
          that it requires AoE damage instead of focusing entirely on single
          target units. As always, paying attention to the mechanics of the
          fight will make the difference between a high raid score or an early
          demise.
        </p>
        <p>
          Below is a quick list of the mechanics in the Dark Knight raid.
          Required mechanics are anything that will end the fight prematurely if
          you don’t use them. Optional mechanics are things that will raise your
          damage, but aren’t strictly necessary for a successful run.
        </p>
        <p>Required mechanics</p>
        <ul>
          <li>Melee units (1+)</li>
        </ul>
        <p>Optional mechanics:</p>
        <ul>
          <li>AoE damage</li>
          <li>Shield</li>
          <li>Taunt</li>
        </ul>
        <h5>Total Darkness (Passive)</h5>
        <StaticImage
          src="../../../images/eversoul/generic/dk_01.webp"
          alt="dk"
        />
        <p>
          The first thing of note when building Dark Knight teams is the melee
          requirement. Teams will often have at least one melee character just
          by default, as building full ranged teams isn’t common, however it is
          a requirement in this raid to have at least one melee character.
          (Mid-range characters such as Talia do not count) Without at least one
          character in melee range of the boss, he will spam his “Despair”
          subskill, which places stacks of “Brand of Despair” on your team. At
          three stacks, your units will die instantly, ending the fight about 6
          seconds in.
        </p>
        <p>
          Aside from this unavoidable instant death mechanic, the amount of
          damage your team takes will also be defined by how many melee
          characters are on the team. With only 1, Dark Knight’s damage will be
          tripled, and with 2 it will be doubled. Running a team with 1-2 melee
          units is perfectly possible, however if you find yourself dying
          frequently, it may help to add more melee units.
        </p>
        <h5>Deception (Main Skill)</h5>
        <p>
          The most noticeable difference between DK and other raids is the
          addition of his entourage. At two points in the battle, DK will vanish
          and summon a group of enemies, and all damage dealt to them counts as
          accumulated damage toward DK. This of course means that bringing AoE
          characters will rack up the damage quite a bit faster than relying on
          single target units, a pretty big change from the usual raid comps.
        </p>
        <p>
          His main skill also has a secondary function, as when he returns from
          hiding he will strike your entire team with a large damage attack that
          stuns for 6 seconds - however the stun effect can be negated by using
          shields. The shields also provide a cushion against the damage, which
          later on in the fight when DK has accumulated a large amount of Atk
          stacks, could save your run.
        </p>
        <h5>Sever (Sub Skill)</h5>
        <p>
          This attack will target the unit furthest away from DK, dealing a
          large amount of damage and stunning them for 3.5 seconds. It is
          unavoidable, however it is good to consider which of your units will
          be taking this hit. Positioning a unit that a) can survive the damage
          and b) won’t hinder your run by being stunned at an unfortunate time
          to bait this attack is important when building your team.
        </p>
        <p>
          Tip - You can avoid having your furthest soul hit, if you have a
          character who can taunt in team and with right timing, you can direct
          the skill to that character instead!
        </p>
        <h5>Taunt</h5>
        <p>
          This isn’t tied to any of DK’s skills, however it is definitely worth
          taking note of. Bringing a character that has a taunt will place a
          debuff on Dark Knight, allowing your whole team to deal more damage!
        </p>
        <StaticImage
          src="../../../images/eversoul/generic/dk_02.webp"
          alt="dk"
        />

        <SectionHeader title="Useful Characters" />
        <p>
          At this point in the game, there are enough characters released that
          there are a significant amount of options that are viable and
          teambuilding is a lot easier. With that in mind, this is not an
          exhaustive list of all the viable options, just one that provides some
          of the best options. Since this raid has a melee unit requirement,
          melee units will be noted but a separate section won’t be provided for
          them, as the list would be too long.
        </p>
        <h5>Main DPS</h5>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="claudia" enablePopover />
          <EversoulCharacter mode="icon" slug="velanna" enablePopover />
          <EversoulCharacter mode="icon" slug="sigrid" enablePopover />
          <EversoulCharacter mode="icon" slug="tasha" enablePopover />
          <EversoulCharacter mode="icon" slug="naomi" enablePopover />
          <EversoulCharacter mode="icon" slug="bryce" enablePopover />
        </div>
        <ul>
          <li>
            Claudia - The queen of AoE finally has a raid debut! With her
            amazing damage potential in AoE attacks, having a Claudia-centric
            team is basically a given against DK.
          </li>
          <li>
            Velanna (melee) - Another AoE unit that makes an appearance on this
            special occasion, Velanna can dish out superb damage in this raid,
            though she does require some help to perform optimally. Pair her in
            a team with Ayame and she’s good to go!
          </li>
          <li>
            Sigrid - The newest addition to Dark Knight is new Undead Soul -
            Sigrid, featuring an AoE on almost all skillset combined with a
            ramping up that can hit extremely hard and fast Main Skill spam due
            to her in-built Mana Gain, Sigrid is force be reckoned in this raid
            and is part of best DPS teams.
          </li>
          <li>
            Bryce (melee) - While she does require her twin to function as a
            DPS, the pair of them is amazing in almost every raid thanks to
            their ability to spam their ultimates. On the plus side, since
            they’re both melee, they also provide further teamwide benefits on
            top of their amazing DPS.
          </li>
          <li>
            Naomi - Despite not being an AoE unit, Naomi is still capable of
            providing a great amount of damage in the DK raid by building up her
            stacks then hitting with a nuke at the end of the fight. She will
            require some form of speed support such as Jiho or Beleth to perform
            optimally.
          </li>
          <li>
            Tasha - Another option that works decently well here, Tasha can use
            the melee unit requirement to her advantage, powering up with every
            melee unit added while DK gets powered down!
          </li>
        </ul>
        <h5>Shields</h5>
        <div className="employee-container for-nikke">
          <EversoulCharacter
            mode="icon"
            slug="catherine-radiance"
            enablePopover
          />
          <EversoulCharacter mode="icon" slug="yuria" enablePopover />
          <EversoulCharacter mode="icon" slug="petra" enablePopover />
          <EversoulCharacter mode="icon" slug="naomi" enablePopover />
        </div>
        <p>
          Proper shield timing can save your run - both by mitigating damage and
          protecting your team from a rather long stun! These characters are the
          best options because they provide shields on top of serving other
          purposes within the raid.
        </p>
        <ul>
          <li>
            Catherine (Radiance) (melee) - Also an amazing support unit, Radiant
            Catherine is sure to be a staple in pretty much every raid going
            forward. She provides way too many benefits to ignore, and her
            shield is just icing on the cake for this raid!
          </li>
          <li>
            Yuria - Not surprisingly, Yuria appears on every raid list
            somewhere. Her shield timing may need some specific tweaking as it
            is on a sub-skill, but her otherwise stellar support kit will always
            win her a spot on raid teams. Bonus points for pairing her with your
            Undead DPS for the faction bonus boost!
          </li>
          <li>
            Petra (melee) - A common sight on Undead teams in raids since the
            beginning, Petra brings both shields and taunt to the fight, and a
            plus one to your melee count.
          </li>
          <li>
            Naomi - It’s hard to say whether her shields are her secondary or
            primary function, since spamming her main skill is basically the
            whole point. DPS and shields in one!
          </li>
        </ul>
        <h5>Taunt</h5>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="linzy" enablePopover />
          <EversoulCharacter mode="icon" slug="petra" enablePopover />
          <EversoulCharacter mode="icon" slug="lute" enablePopover />
        </div>
        <p>
          While not entirely necessary in this raid (as in, the mechanic won’t
          cause your demise if you ignore it), bringing characters that apply
          taunt will raise your DPS!
        </p>
        <ul>
          <li>
            Linzy (melee) - A great option as she brings decent damage on top of
            providing a taunt, Linzy is easy to slot into a human team if you
            use one. If not, slotting her in a team that is low on melee units
            is also a decent option.
          </li>
          <li>
            Petra (melee) - Mentioned previously, Petra is a great addition to
            most Undead teams, providing team survivability via shields and
            taunt for more damage.
          </li>
          <li>
            Lute (melee) - A great staple in any Tasha-centric team, Lute
            provides taunt, damage, and another melee unit to boost your Tasha
            and reduce DK’s damage.
          </li>
        </ul>
        <h5>Supports</h5>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="larimar" enablePopover />
          <EversoulCharacter mode="icon" slug="joanne" enablePopover />
          <EversoulCharacter mode="icon" slug="beleth" enablePopover />
          <EversoulCharacter mode="icon" slug="jiho" enablePopover />
          <EversoulCharacter mode="icon" slug="garnet" enablePopover />
          <EversoulCharacter mode="icon" slug="ayame" enablePopover />
          <EversoulCharacter mode="icon" slug="talia" enablePopover />
          <EversoulCharacter mode="icon" slug="vivienne" enablePopover />
          <EversoulCharacter mode="icon" slug="dominique" enablePopover />
        </div>
        <p>
          The list of viable supports is getting longer and longer - in addition
          to these, Radiant Cath and Yuria, who were listed above, are two more
          of the best supports available. As noted, this list is not exhaustive.
        </p>
        <ul>
          <li>
            Larimar - No list is complete these days without mentioning Larimar,
            who has completely shaken and broken game's existing Meta due to her
            S1. Larimar again is unmatched here can be utilized with either
            Velanna or Sigrid or Bryce/Melfice teams making their damage jump
            throught the roof.
          </li>
          <li>
            Beleth - Provides her team with a plethora of amazing DPS buffs,
            great heals, and if using her on a team where she’s the one Ulting,
            mana too! She also has a self-shield that can potentially block the
            stun on herself if the timing lines up, allowing her to use her main
            skill to cleanse it from the rest of her team.
          </li>
          <li>
            Garnet - The uncontended master of debuffs, Garnet’s abilities allow
            her team to deal significantly more damage, and provides
            survivability through lifesteal as well!
          </li>
          <li>
            Ayame - The ideal support for a Velanna-centric team, Ayame causes
            enemies to bleed which helps Velanna deal a lot more damage. She
            also deals great damage in her own right, and debuffs the enemies
            for her team.
          </li>
          <li>
            Joanne - A great option for Undead teams, Joanne provides both
            debuffs for the enemies and heals for your team.
          </li>
          <li>
            Talia - Still a great option as a team filler due to her mana
            generation, she’s not as sought-after a teammate as she once was.
            But if you need a filler unit, she’s a great option!
          </li>
          <li>
            Vivi - Similar to Talia, Vivi’s usefulness has diminished slightly
            over time, however her teamwide speed buff still keeps her in the
            running as a team filling option.
          </li>
          <li>
            Jiho - Since the addition of Beleth, Jiho has been demoted to
            secondary speed-boost healer, though she is still a great option for
            the Naomi-centric team if Beleth isn’t available.
          </li>
          <li>
            Dominique - The Queen of Troyca is very strong support in Dark
            Knight raid when paired with Claudia, as her INT damage boosts take
            Claudia Team damage to the next level by itself and she has lot more
            to offer.
          </li>
        </ul>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default EsGuidesDarkKnight;

export const Head: React.FC = () => (
  <Seo
    title="Dark Knight - raid guide | Eversoul | Prydwen Institute"
    description="Struggling with the Dark Knight Raid? This guide will help you!"
    game="eversoul"
  />
);
